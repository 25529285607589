.city-logo-container {
  margin-top: 50px;
  text-align: center;
}

.notice-title-container,
.notice-message-container {
  text-align: center;
  font-family: "Roboto", sans-serif;
  margin-right: 60px;
  margin-left: 60px;
}

.notice-message-container p {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 20px;
}

.city-logo-it {
  height: 130px;
}
